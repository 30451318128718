<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="multipleItemsSelectedCount" class="side-panel-selected-count">
      <p>
        {{ __("Selected") }}
        <el-tag>
          <strong>{{ multipleItemsSelectedCount }}</strong>
        </el-tag>
        {{ __("items") }}.
      </p>
    </div>
    <div v-else class="side-panel-content-info">
      <div class="field-group">
        <div class="titles">{{ __("Name") }}</div>
        <div class="details">{{ contentFormInfo.variable_name }}</div>
      </div>

      <div
        v-if="
          contentFormInfo.variable_type === 'single_value' &&
            contentFormInfo.default_value
        "
        class="field-group"
      >
        <div class="titles">{{ __("Default Value") }}</div>
        <div class="details">{{ contentFormInfo.default_value }}</div>
      </div>
      <div
        v-if="contentFormInfo.variable_type === 'single_value'"
        class="field-group"
      >
        <div class="titles">{{ __("Passed Parameter") }}</div>
        <div class="details">
          {{ passedParameterLan }}
        </div>
      </div>
    </div>

    <div style="margin-top: 20px;" class="side-panel-action-list">
      <el-dropdown
        @command="command => handleAction(command)"
        placement="bottom-start"
      >
        <el-button plain class="btn-plain">{{ __("Actions") }}</el-button>
        <el-dropdown-menu slot="dropdown" style="width: 200px;">
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="edit"
            :disabled="!can('content.variables.write')"
          >
            <span>{{ __("Edit") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            command="move"
            :disabled="!can('content.variables.write')"
          >
            <span>{{ __("Move to folder") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="check_in_use"
            :disabled="!!contentFormInfo.is_refreshing"
          >
            <span>{{ __("Check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="refresh"
          >
            <span>{{ __("Refresh check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            :disabled="!can('content.variables.write')"
            command="delete"
          >
            <span>{{ __("Delete") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      v-if="openModal"
      :visible.sync="openModal"
      custom-class="variable-form-dialog"
      destroy-on-close
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      fullscreen
      :modal="false"
    >
      <div class="variable-form-wrapper max-vh">
        <el-form
          ref="variableForm"
          label-position="top"
          label-width="100px"
          :model="contentForm"
          class="form-container"
          :rules="rules"
          hide-required-asterisk
        >
          <variable-form
            :value="contentForm"
            :content-id="id"
            @file-uploaded="addToUploadedFiles"
          />
        </el-form>
      </div>
      <div style="display: flex;margin-left: 25%;margin-bottom: 20px">
        <save-button
          :disabled="!contentUpdated || !can('content.variables.write')"
          type="primary"
          @click="submitForm"
          class="submitBtn"
          :loading="loading"
          :primaryKey="id"
          variant="CreateUpdate"
        />
        <el-button :disabled="loading" @click="handleCancel" class="cancelBtn"
          >{{ __("Cancel") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VariableForm from "../components/VariableForm";
import { mapState, mapActions } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { validateVariable } from "@/api/variables";
import { EventBus } from "@/EventBus";
import { SYS_VAR } from "@/constants/systemVariables";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";
import SaveButton from "@/components/SaveButton";
import {
  CHECK_IN_USE_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  MOVE_ACTION,
  REFRESH_ACTION
} from "@/constants/contents";

export default {
  mixins: [BaseContentInfoPanel, BaseContent],
  components: { VariableForm, SaveButton },

  data() {
    let validateVariableName = async (rule, value, callback) => {
      let reservedVariableNames = SYS_VAR.PREFIX + SYS_VAR.SEPARATOR;
      let regEx = new RegExp(
        "^(" + _.escapeRegExp(reservedVariableNames) + "|XSIP_)",
        "g"
      );
      if (value.match(regEx) !== null) {
        callback(__("reserved variable name ':value'", { value: value }));
      } else {
        let hasError = false;
        await validateVariable(value)
          .then(res => {
            if (
              res.data.found &&
              value !== this.contentFormInfo.variable_name
            ) {
              hasError = true;
            }
          })
          .catch(() => {
            hasError = false;
          });

        if (hasError) {
          callback(__("Variable already exists"));
        } else {
          callback();
        }
      }
    };

    return {
      rules: {
        variable_name: [
          {
            required: true,
            message: __("Please input variable name"),
            trigger: "blur"
          },
          {
            max: 100,
            message: __("Variable name should be no more than 100 characters"),
            trigger: "blur"
          },
          { validator: validateVariableName, trigger: "blur" }
        ]
      },
      additionalValidateRoute: "variables",
      filesUploaded: [],
      exitingAudioFile: "",
      isContent: true,
      viewMode: "allow_read",
      forceDeleteEventName: "initiate-force-delete-Variable"
    };
  },

  computed: {
    ...mapState("variables", {
      loading: state => state.loading
    }),
    ...mapState("folders", {
      selectedFolderId: state => state.selectedFolderId
    }),
    passedParameterLan() {
      return this.contentFormInfo.passed_parameter ? __("Yes") : __("No");
    }
  },

  methods: {
    ...mapActions("variables", {
      createVariable: "createVariable",
      updateVariable: "updateVariable",
      setLoading: "setLoading",
      deleteContentMethod: "deleteVariable",
      undoDeleteContent: "undoDeleteVariable",
      refreshVariable: "refreshVariable"
    }),

    addToUploadedFiles(path) {
      this.filesUploaded.push(path);
    },

    submitForm() {
      this.setLoading(true);
      this.$refs.variableForm.validate(valid => {
        if (valid) {
          this.contentForm.folder_id = this.selectedFolderId;
          const process = Object.keys(this.contentForm).includes("variable_id")
            ? this.updateVariable
            : this.createVariable;
          process(this.contentForm)
            .then(data => {
              this.$message({
                type: "success",
                message: __("Operation successful")
              });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err.message
              });
            });
        } else {
          this.setLoading(false);
        }
      });
    },

    openMoveToFolderDialog() {
      let content_ids = [this.contentForm.variable_id];
      if (this.checkedContents.length) {
        content_ids = this.checkedContents.map(item => {
          if (item && item.variable_id) {
            return item.variable_id;
          }
        });
      }

      EventBus.$emit("open-move-to-folder-modal", {
        content_ids: [...content_ids],
        content_model: "Variable"
      });
    },

    openCheckInUseDialog() {
      EventBus.$emit("open-check-in-use-modal", {
        content_model: "Variable",
        content_id: this.contentForm.variable_id
      });
    },

    refreshCheckInUse() {
      // call backend api
      EventBus.$emit("call-refresh-check-in-use-job", {
        content_model: "Variable",
        content_id: this.contentForm.variable_id,
        content_name: this.contentForm.variable_name,
        refresh: this.refreshVariable
      });
    },

    handleAction(command) {
      switch (command) {
        case EDIT_ACTION:
          this.handleEdit();
          break;
        case MOVE_ACTION:
          this.openMoveToFolderDialog();
          break;
        case CHECK_IN_USE_ACTION:
          this.openCheckInUseDialog();
          break;
        case REFRESH_ACTION:
          this.refreshCheckInUse();
          break;
        case DELETE_ACTION:
          this.handleDelete(
            this.contentForm,
            "Variable",
            this.contentForm.variable_id
          );
          break;
      }
    }
  },
  watch: {
    contentForm: {
      deep: true,
      handler(newVal, oldVal) {
        if (
          newVal.variable_type === "audio" &&
          newVal.audio_file_url &&
          _.get(oldVal, "variable_id") !== _.get(newVal, "variable_id")
        ) {
          this.exitingAudioFile = newVal.audio_file_url;
        } else {
          this.exitingAudioFile = "";
        }
      }
    }
  },
  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });
  },
  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/content-edit-info.scss";

::v-deep .variable-form-dialog {
  min-width: 600px !important;
  padding: 0;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}

.content-sidebar {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  .content-sidebar-body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .content-sidebar-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    // todo to update/remove after getting rid of global content creator from outside canvas
    margin-bottom: 50px;
  }
}

.variable-form-wrapper {
  display: flex;
  justify-content: center;
}

.form-footer {
  display: flex;

  .empty {
    flex: 1;
  }

  .button-group {
    margin-right: 25%;
  }
}
</style>
