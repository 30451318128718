<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "VariableContentsIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },
  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapGetters("variables", {
      contents: "singleValuedAndAudioAndSecureVariables"
    })
  },

  methods: {
    ...mapActions("variables", {
      resetVariables: "resetVariables"
    }),
    initContent({ variable_type } = { variable_type: "single_value" }) {
      return {
        variable_name: "",
        passed_parameter: false,
        scope: "session",
        variable_type: variable_type,
        default_value: "",
        is_enabled: 1
      };
    }
  },

  created() {
    this.resetVariables();
  },

  mounted() {
    this.contentType = "Variable";
    this.primaryKey = "variable_id";
  }
};
</script>

<style lang="scss" scoped></style>
