<template>
  <div class="content-component" @click="handleClearSelection">
    <el-button
      v-show="!can('content.' + additionalValidateRoute + '.write')"
      :disabled="!can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      >{{ __("Create Variable") }}</el-button
    >
    <el-dropdown
      v-show="can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      split-button
      type="primary"
      @click.stop="createContentItem({ variable_type: 'single_value' })"
      @command="handleCommand"
    >
      {{ __("Create Variable") }}
      <el-dropdown-menu class="mediumDropdown" slot="dropdown">
        <el-dropdown-item
          command="single_value"
          :disabled="!can('content.' + additionalValidateRoute + '.write')"
          >{{ __("Create Variable") }}</el-dropdown-item
        >
        <el-dropdown-item
          command="audio"
          :disabled="!can('content.' + additionalValidateRoute + '.write')"
          >{{ __("Create Audio Variable") }}</el-dropdown-item
        >
        <el-dropdown-item
          command="secure"
          :disabled="!can('content.' + additionalValidateRoute + '.write')"
          >{{ __("Create Secure Variable") }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :show-content-view-switcher="true"
            :content-api="contentAPI"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar v-loading="loading" :native="true">
          <div class="contentCardsContainer">
            <el-row
              v-if="variables.length"
              v-show="can('content.' + additionalValidateRoute + '.read')"
              :class="contentViewClasses"
            >
              <content-list-item
                :content-view-type="contentViewType"
                v-for="(variable, index) in variables"
                :item-index="index"
                :key="'_content_variable_' + index"
                :draggable-data="{
                  content_id: selectedItems.length
                    ? selectedIds
                    : [variable[primaryKey]],
                  content_type: contentType
                }"
                :allow-multi-select="true"
                :card-class="{ selected: selectedItemIndex === index }"
                icon="icon-variable.svg"
                :card-text="variable.variable_name"
                :content-list-item="variable"
                :fields-to-display="displayFields"
                @delete="
                  handleDelete(variable, 'Variable', variable.variable_id)
                "
                @select="handleSelect(index, variable)"
                @edit="handleEdit(index, variable)"
                @checked-content="handleSelectMultiple($event)(variable)"
                @toggle-select-all="
                  handleToggleSelectAll(variables, ...arguments)
                "
                :is-all-selected="isAllSelected"
                :total-items-selected="selectedItems.length"
              >
                <template #passed_parameter>
                  <div>
                    <i
                      class="el-icon-check"
                      v-if="variable.passed_parameter"
                    ></i>
                  </div>
                </template>
              </content-list-item>
            </el-row>
            <el-row
              v-else-if="!loading"
              style="padding-top: 100px;"
              type="flex"
              justify="center"
            >
              {{ __("No Variables") }}
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentListItem from "@/views/build/content/mixins/ContentListItem";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";

export default {
  name: "VariableContentsList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentListItem
  },
  data() {
    return {
      additionalValidateRoute: "variables",
      sortByColumns: ["variable_name", "variable_id"],
      isContent: true,
      viewMode: "allow_read",
      displayFields: [
        {
          field_name: "variable_name",
          display_name: __("Name")
        },
        {
          field_name: "default_value",
          display_name: __("Default Value")
        },
        {
          field_name: "variable_type_name",
          display_name: __("Type")
        },
        {
          field_name: "passed_parameter",
          display_name: __("Passed Parameter?"),
          use_slot: true
        }
      ]
    };
  },

  computed: {
    ...mapState("variables", {
      loading: state => state.loading
    }),

    ...mapGetters("variables", {
      singleValuedAndAudioAndSecureVariablesNoGroup:
        "singleValuedAndAudioAndSecureVariablesNoGroup"
    }),

    variables() {
      return this.variablesWithTypeName;
    },

    selectedIds() {
      return _.map(this.selectedItems, this.primaryKey);
    },
    variableTypeNames() {
      const typeNames = {
        single_value: "Single",
        secure: "Secure",
        payment: "Payment",
        audio: "Audio",
        system: "System",
        xsip: "Xsip"
      };
      return {
        get(variableType) {
          let typeName = typeNames[variableType];
          if (!typeName) {
            typeName = _.capitalize(variableType);
          }
          return typeName;
        }
      };
    },
    variablesWithTypeName() {
      return this.singleValuedAndAudioAndSecureVariablesNoGroup.map(
        variable => {
          return {
            ...variable,
            variable_type_name: this.variableTypeNames.get(
              variable.variable_type
            )
          };
        }
      );
    }
  },

  methods: {
    ...mapActions("variables", {
      contentAPI: "forceFetchVariables"
    }),
    handleCommand(command) {
      this.createContentItem({ variable_type: command });
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";
</style>
<style lang="scss">
.content-variable-list {
  .passed_parameter {
    display: none !important;
  }
}
</style>
