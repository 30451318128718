<template>
  <div>
    <el-row type="flex" style="padding-top: 34px">
      <el-col :span="12" :offset="6">
        <page-header
          :title="
            __(':audio :secure Variable', {
              audio: isAudioVariable ? __('Audio') : '',
              secure: isSecureVariable ? __('Secure') : ''
            })
          "
          :content-id="contentId"
        />
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12" :offset="6">
        <el-form-item prop="variable_name" :label="__('Name')">
          <el-input id="variableNameField" v-model="variable.variable_name" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row v-if="isSingleValuedVariable">
      <el-col :span="12" :offset="6">
        <el-form-item prop="passed_parameter">
          <div slot="label" class="form-label">
            <div>{{ __("Passed Parameter") }}</div>
            <el-popover
              placement="right"
              width="325"
              trigger="hover"
              :content="passedParamsContent"
            >
              <i slot="reference" class="el-icon-info icon"></i>
            </el-popover>
          </div>
          <el-radio-group v-model="variable.passed_parameter">
            <el-radio :label="true">{{ __("Yes") }}</el-radio>
            <el-radio :label="false">{{ __("No") }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>

    <!--    <el-row>-->
    <!--      <el-col :span="12" :offset="6">-->
    <!--        <el-form-item prop="variable_type" label="Variable Type">-->
    <!--          <el-select v-model="variable.variable_type" placeholder="Select">-->
    <!--            <el-option-->
    <!--              v-for="variableType in variableTypes"-->
    <!--              :key="variableType.label"-->
    <!--              :label="variableType.label"-->
    <!--              :value="variableType.value"-->
    <!--            >-->
    <!--            </el-option>-->
    <!--          </el-select>-->
    <!--        </el-form-item>-->
    <!--      </el-col>-->
    <!--    </el-row>-->

    <el-row>
      <el-col :span="12" :offset="6">
        <el-form-item prop="default_value" :label="secureVariableLan">
          <el-input
            v-if="isSingleValuedVariable || isSecureVariable"
            v-model="variable.default_value"
          />
          <wav-file-uploader
            v-else-if="isAudioVariable"
            folder="audio-vars"
            :file-url="audioVarUrl"
            :file-name="audioFileName"
            :max-size-in-mb="3"
            @on-success="handleAudioFileUploadSuccess"
            @on-delete="handleAudioFileRemove"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader";
import WavFileUploader from "@/components/uploaders/types/WavFileUploader";
import path from "path";

export default {
  components: { WavFileUploader, PageHeader },
  props: {
    value: {
      type: Object,
      required: true
    },
    contentId: {
      required: false,
      type: Number,
      default: -1000
    }
  },
  data() {
    return {
      variable: {},
      audioVarUrl: ""
    };
  },
  computed: {
    isCreatingNewVariable() {
      return !_.has(this.variable, "variable_id");
    },
    isSingleValuedVariable() {
      return this.variable.variable_type === "single_value";
    },
    isAudioVariable() {
      return this.variable.variable_type === "audio";
    },
    isSecureVariable() {
      return this.variable.variable_type === "secure";
    },
    audioFileName() {
      return this.variable.default_value
        ? this.getTailName(this.variable.default_value)
        : "";
    },
    secureVariableLan() {
      return this.isSecureVariable ? __("Value") : __("Default Value");
    },

    /**
     * Text for the passed parameters Info tooltip
     * @returns {String} Translated message for the passed parameters tooltip content
     */
    passedParamsContent() {
      // eslint-disable-next-line
      return __('When set to Yes, the variable can be used as a passed parameter for workflow tasks and campaigns.');
    }
  },
  methods: {
    initializeVariable() {
      this.variable = this.value;
      this.audioVarUrl = _.get(this.value, "audio_file_url", "");
    },
    pushToFilesUploaded(path) {
      !_.includes(this.filesUploaded, path) && this.filesUploaded.push(path);
    },
    getName(p) {
      return path.basename(p);
    },

    getTailName(p) {
      return _(_.split(this.getName(p), "_"))
        .tail()
        .value()
        .join("_");
    },
    handleAudioFileUploadSuccess({ path, url }) {
      this.variable.default_value = path;
      this.audioVarUrl = url;
      this.$set(this.variable, "audio_file_url", url);
      this.$emit("file-uploaded", path);
    },
    handleAudioFileRemove() {
      this.variable.default_value = "";
      this.$set(this.variable, "audio_file_url", "");
      this.audioVarUrl = "";
    }
  },

  watch: {
    value: {
      immediate: true,
      handler: "initializeVariable"
    }
  }
};
</script>

<style lang="scss" scoped>
.form-container {
  min-width: 450px;
}
</style>
